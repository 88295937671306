@each $key, $code in $colors {
	.text-#{$key} {
		color: $code;
	}
}

@each $value in lowercase, uppercase, capitalize {
	.text-#{$value} {
		text-transform: $value;
	}
}
