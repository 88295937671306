////
/// @group HELPERS
/// @author CUBIT
////

/// flexアイテムを水平方向に配置するmixin
/// flexアイテムの最初と最後は余白が与えられる
@mixin flex-around {
	justify-content: space-around;
}
