////
/// @group HELPERS
/// @author CUBIT
////

/// flexアイテムの折り返しを許可するmixin
@mixin flex-wrap {
	box-lines: multiple;
	-webkit-box-lines: multiple;
	-ms-flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
}
