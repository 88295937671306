.l-row {
	@include flex;
}

/*
 * _config.scssの$l-colで定義してあるpaddingを適用
 */
@each $key in map-keys($l-col) {
	$size: $key;
	$col: map-get($l-col, #{$size});

	@if $col != null {
		.l-row {
			@include mq(#{$size}) {
				& {
					$margin: map-get($col, padding);

					margin: {
						left: -$margin;
						right: -$margin;
					}
				}
			}
		}
	}
}
