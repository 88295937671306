@import 'normalize.css/normalize.css';
@import './_font-face.scss';

@if map-get($use, universal-box-sizing) {
	*,
	*::before,
	*::after {
		box-sizing: border-box;
	}
}

html {
	@if map-get($use, rem) {
		font-size: 62.5%;
		font-size: calc(1em * .625);
	}

	@if map-get($use, antialiase) {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	@each $key, $value in $html {
		#{$key}: $value;
	}
}

body {
	@each $key, $value in $body {
		#{$key}: $value;
	}
}

h1, h2, h3, h4, h5, h6 {
	margin: 0;
	font-weight: normal;
}

ul, ol, li {
	margin: 0;
	padding: 0;
}

ul, ol {
	list-style: none;
}

dl, dt, dd {
	margin: 0;
}

img, svg {
	vertical-align: middle;
	max-width: 100%;
}

em {
	font-style: normal;
}

input {
	border: none;
}

button {
	border: none;
	background: none;
	padding: 0;
	cursor: pointer;
	border-radius: 0;
}

strong {
	font-weight: normal;
}

p {
	margin: 0;
}

address {
	font-style: normal;
}

figure {
	margin: 0;
}

dfn {
	font-style: normal;
}

i {
	font-style: normal;
}

a {
	$link: map-get($default, link);
	$link-hover: map-get($default, link-hover);

	color: map-get($link, color);
	text-decoration: map-get($link, decoration);

	&:hover {
		color: map-get($link-hover, color);
		text-decoration: map-get($link-hover, decoration);
	}
}

::-webkit-input-placeholder {
	padding-top: .2rem;
}
