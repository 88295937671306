@each $value in normal, bold {
	.font-#{$value} {
		font-weight: $value;
	}
}

@each $value in italic {
	.font-#{$value} {
		font-style: $value;
	}
}
