////
/// @group HELPERS
/// @author CUBIT
////

/// flexレイアウトを指定するmixin
@mixin flex {
	display: -ms-flexbox;
	display: -webkit-box;
	display: flex;
	-ms-flex: 0 1 auto;
	-webkit-box-flex: 0;
	flex: 0 1 auto;
	-ms-flex-direction: row;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	flex-direction: row;
}
