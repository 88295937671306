////
/// @group HELPERS
/// @author CUBIT
////

/// flexアイテムを上下方向に配置するmixin
/// flexアイテムを中央に配置
@mixin flex-middle {
	align-items: center;
}
