////
/// @group HELPERS
/// @author CUBIT
////

/// flexアイテムを水平方向に配置するmixin
/// flexアイテムを始端に配置
@mixin flex-start {
	justify-content: flex-start;
	text-align: start;
}
