/*
 * 12分割用のグリッドを生成
 */
$col: (
	1: '8.333333%',
	2: '16.666667%',
	3: '25%',
	4: '33.333333%',
	5: '41.666667%',
	6: '50%',
	7: '58.333333%',
	8: '66.666667%',
	9: '75%',
	10: '83.333333%',
	11: '91.666667%',
	12: '100%'
);

@each $key, $width in $col {
	@include generate(".l-col-#{$key}", true) {
		width: #{$width};
		max-width: #{$width};
	}
}

/*
 * _config.scssの$l-colで定義してあるpaddingを適用
 */
@each $key in map-keys($l-col) {
	$size: $key;
	$col: map-get($l-col, #{$size});

	@if $col != null {
		[class*="l-col"] {
			@include mq(#{$size}) {
				& {
					$padding: map-get($col, padding);

					padding: {
						left: $padding;
						right: $padding;
					}
				}
			}
		}
	}
}
