////
/// @group HELPERS
/// @author CUBIT
////

/// flexアイテムを水平方向に配置するmixin
/// flexアイテムの最初と最後は端に寄せられる
@mixin flex-between {
	justify-content: space-between;
}
