////
/// @group HELPERS
/// @author CUBIT
////

/// flexアイテムを上下方向に配置するmixin
/// flexアイテムを始端に配置
@mixin flex-bottom {
	align-items: flex-end;
}
