////
/// @group HELPERS
/// @author CUBIT
////

/// `frontend/src/assets/public/styles/partial/_config.scss`
/// の`$breakpoints`で定義してあるmapを元に各種ブレイクポイント用のスタイルを適用するmixin
/// 
/// @argument {string} $value $breakpointsで定義したkeyを指定
/// 
@mixin mq($value: md) {
	@media only screen and #{map-get($breakpoints, $value)} {
		@content;
	}
}
