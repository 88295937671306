////
/// @group HELPERS
/// @author CUBIT
////

/// `frontend/src/assets/public/styles/partial/_config.scss`
/// の`$breakpoints`で定義してあるmapを元に各種ブレイクポイント用のクラスを生成するmixin
/// 
/// @param {string} $class [] - CSSのクラス名を指定
/// @param {boolean} $add-suffix [] - ブレイクポイント毎の接尾辞をつけるかどうか
@mixin generate($class, $add-suffix) {
	#{$class} {
		@content;
	}

	@each $suffix, $value in $breakpoints {
		@include mq(#{$suffix}) {
			@if $add-suffix != null {
				@if $add-suffix {
					#{$class}-#{$suffix} {
						@content;
					}
				}
			} @else {
				#{$class} {
					@content;
				}
			}
		}
	}
}
