@font-face {
	$last-updated: 20180401;
	$path: map-get($path, fonts);
	$family: map-get($font-icons, family);
	$file-name: map-get($font-icons, file-name);

	font-family: #{$family};
	src: url('#{$path}/#{$file-name}.eot?#{$last-updated}');
	src: url('#{$path}/#{$file-name}.eot?#{$last-updated}#iefix') format('embedded-opentype'), url('#{$path}/#{$file-name}.svg?#{$last-updated}#static/fontello') format('svg'), url('#{$path}/#{$file-name}.woff?#{$last-updated}') format('woff'), url('#{$path}/#{$file-name}.ttf?#{$last-updated}') format('truetype');
	font-weight: normal;
	font-style: normal;
}
