.l-container {
	width: 100%;
	margin: {
		left: auto;
		right: auto;
	}

	/*
	 * _config.scssの$l-containerで定義してあるpaddingを適用
	 */
	@each $key in map-keys($breakpoints) {
		$size: $key;
		$container: map-get($l-container, #{$size});

		@if $container != null {
			@include mq(#{$size}) {
				& {
					$padding: map-get($container, padding);

					padding: {
						left: $padding;
						right: $padding;
					}
				}
			}
		}

		/*
		 * _config.scssの$widthで定義してあるmain要素横幅をmax-widthに指定
		 */
		@if $size == xl {
			@include mq(xl) {
				$container: map-get($l-container, xl);
				$padding: map-get($container, padding);
				$width: map-get($container, width);

				@if $width != null {
					& {
						max-width: $width + $padding + $padding;
					}
				}
			}
		}
	}
}
