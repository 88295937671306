
.icon {
	&::before {
		font-family: map-get($font-icons, family);
		line-height: 1;
	}

	@each $name, $icon in map-get($font-icons, icon) {
		&.icon-#{$name} {
			&::before {
				content: #{$icon};
			}
		}
	}
}
