////
/// @group HELPERS
/// @author CUBIT
////

/// flexアイテムを水平方向に配置するmixin
/// flexアイテムを中央に配置
@mixin flex-center {
	justify-content: center;
	text-align: center;
}
