////
/// @group HELPERS
/// @author CUBIT
////

/// flexアイテムを水平方向に配置するmixin
/// fflexアイテムを終端に配置
@mixin flex-end {
	justify-content: flex-end;
	text-align: end;
}
